<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.project.name.$errors.length }"
                        :label="$t('forms.name')"
                        label-for="name"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="project.name"
                            id="name"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.project.name.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.project.description.$errors.length }"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="project.description"
                            id="description"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.project.description.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.typeSelect.$errors.length }"
                        :label="$t('forms.type')"
                        label-for="type"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="type"
                            v-model="typeSelect"
                            :options="types"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.typeSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.statusSelect.$errors.length }"
                        :label="$t('forms.status')"
                        label-for="statusSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="statusSelect"
                            v-model="statusSelect"
                            :options="statuses"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.statusSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.sharedDepartments')"
                        label-for="sharedDepartments"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select

                            v-model="departmentSelect"
                            :options="departments"
                            multiple
                        />

                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.fromDate')"
                        label-for="fromDate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="fromDate"
                            v-model="project.fromDate"
                            :enableTimePicker="false"
                            class="form-input"
                            format="dd.MM.yyyy"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.project.fromDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.toDate')"
                        label-for="toDate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="toDate"
                            v-model="project.toDate"
                            :enableTimePicker="false"
                            class="form-input"
                            format="dd.MM.yyyy"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.project.toDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6"></b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        projectObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getResourceList(this.$Departments, this.departments)
        this.getEnumList(["project_status", "project_type"], this.statuses, this.types);
        if ('id' in this.project) {
            if (this.project.sharedDepartments) {
                this.project.sharedDepartments.forEach(element => {
                    this.departmentSelect.push({
                        label: element.name,
                        id: element['@id']
                    })
                });
            }
            this.statusSelect = {
                label: this.$helper.getEnumTranslation('project_status', this.project.status, this.$i18n.locale),
                id: this.project.status
            };
            this.typeSelect = {
                label: this.$helper.getEnumTranslation('project_type', this.project.type, this.$i18n.locale),
                id: this.project.type
            };
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            statusSelect: {required},
            typeSelect: {required},
            project: {
                description: {required},
                name: {required},
                fromDate: {required},
                toDate: {required}
            },
        };
    },
    data() {
        return {
            project: this.projectObject,
            departments: [],
            departmentSelect: [],
            statuses: [],
            statusSelect: null,
            types: [],
            typeSelect: {}
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                let sharingDepartments = []
                if (this.typeSelect) {
                    this.project.type = this.typeSelect.id
                }
                if (this.statusSelect) {
                    this.project.status = this.statusSelect.id
                }
                if (this.departmentSelect.length) {
                    this.departmentSelect.forEach(element => {
                        sharingDepartments.push(element.id)
                    });
                }
                this.project.sharingDepartments = sharingDepartments
                if (this.action === "add") {
                    this.create(this.$Projects, this.project, this.$t("messages.projectCreated"), null, this.success, this.error);
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(this.$Projects, this.project.id, this.project, this.$t("messages.projectUpdated"), null, this.success, this.error);
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/projects`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

